import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBookRide from './book-ride.reducer';
import { AuthSelectors } from 'src/app/auth/store';
import {
  mapAgentToTemplates,
  mapBookingAggregateToUpcomingRidesTableVM,
  mapBusinessNoteToNoteToDriver,
  mapLocationToLocationVM,
  mapPackagesToProductVM,
  mapQuoteToQuoteVM,
} from './mappings';
import { LatLng } from '../../../shared/models/latlng.model';
import { HomeSelectors } from '../../store';
import { dateTimeNowAddDuration } from '../../../shared/utils/datetime';
import { getRideStatus, mapBookingAggregateToRideDetails } from '../../rides/store/mappings';
import { toZonedTime } from 'date-fns-tz';
import { RideStatus } from '../../../shared/models/rides.vm';
import { MetaOp } from 'src/app/shared/models/firestore.model';
import { addMonths, isBefore } from 'date-fns';

export const selectBookRideState = createFeatureSelector<fromBookRide.State>(fromBookRide.bookRideFeatureKey);

const selectAgent = createSelector(AuthSelectors.selectAgentAggregate, agentAggregate => agentAggregate?.agent);
const selectBusiness = createSelector(HomeSelectors.selectBusiness, business => business);
export const selectBusinessPaymentMethodTypes = createSelector(
  selectBusiness,
  business => business?.paymentMethodTypes,
);

export const selectAgentPaymentCards = createSelector(
  HomeSelectors.selectBusinessAggregate,
  selectAgent,
  (businessAggregate, agent) => {
    if (!businessAggregate || !businessAggregate.cards?.length) {
      return [];
    }
    const paymentCardIds = businessAggregate.cards
      .filter(card => card.agent_ids.includes(agent.id))
      .map(card => card.payment_card_id);

    const paymentCards = businessAggregate.payment_cards
      .filter(card => paymentCardIds.includes(card.id))
      .filter(card => card.meta_op !== MetaOp.DELETE);

    const currentDate = new Date();
    return paymentCards.map(card => {
      const expirationDate = new Date(card.expiry_year, card.expiry_month - 1);
      const expired = isBefore(expirationDate, currentDate);
      const expiringSoon = !expired && isBefore(expirationDate, addMonths(currentDate, 3));
      return { ...card, expiringSoon, expired };
    });
  },
);

export const selectAgentPhone = createSelector(selectAgent, agent => agent?.phone);

export const selectBusinessId = createSelector(selectAgent, agent => agent?.business_id);
export const selectBusinessSiteId = createSelector(AuthSelectors.selectFcUserId, userid => userid);

export const selectAllPackages = createSelector(selectBookRideState, state => state.allPackages);
export const selectAllVehicleTypes = createSelector(selectBookRideState, state => state.allVehicleTypes);

const selectAreas = createSelector(selectBookRideState, state => state.areas);

const selectAreaState = createSelector(selectBookRideState, state => state.areaState);
export const selectAreaId = createSelector(selectAreaState, state => state.areaId);
export const selectPackages = createSelector(selectAreaState, state => state.packages.items);
export const selectPackagesIsLoaded = createSelector(selectAreaState, state => state.packages.loaded);
export const selectVehicleTypes = createSelector(selectAreaState, state => state.vehicleTypes.items);
export const selectVehicleTypesIsLoaded = createSelector(selectAreaState, state => state.vehicleTypes.loaded);
export const selectFeatures = createSelector(selectAreaState, state => state.features.items);
export const selectFeaturesIsLoaded = createSelector(selectAreaState, state => state.features.loaded);
export const selectUpcomingRides = createSelector(selectBookRideState, state => state.upcomingRides);
export const selectPlatformSetting = createSelector(selectBookRideState, state => state.settings);
export const selectLocalPickupAtDateTime = createSelector(selectBookRideState, state => state.localPickupAtDateTime);
export const selectPaymentMethod = createSelector(selectBookRideState, state => state.paymentMethod);
export const selectRoute = createSelector(selectBookRideState, state => state.rideRoute);
export const selectIsPrebooking = createSelector(selectBookRideState, state => state.isPrebooking);
export const selectBusinessNote = createSelector(selectBookRideState, state => state.businessNote);

export const selectAreaTimeZoneId = createSelector(selectAreaId, selectAreas, (areaId, areas) => {
  // @TODO check if this is correct and we don't need to throw an error if areaId is null
  return areas?.find(area => area.id === areaId)?.time_zone_id ?? 'Europe/Copenhagen';
});

export const selectIsLoading = createSelector(selectBookRideState, state => state.isLoading);
export const selectIsLoadingQuote = createSelector(selectBookRideState, state => state.isLoadingQuote);
export const selectMinDurationToPickupSettingValues = createSelector(selectPlatformSetting, settings =>
  settings ? settings['b2b.prebooking_min_duration_to_pickup'].values : [],
);
export const selectMaxDurationToPickupSettingValues = createSelector(selectPlatformSetting, settings =>
  settings ? settings['b2b.prebooking_max_duration_to_pickup'].values : [],
);
export const selectAllowContactDriverAfterTripDuration = createSelector(selectPlatformSetting, settings =>
  settings ? settings['b2b.allow_contact_driver_after_trip_duration'].values : [],
);

export const selectMinDurationToPickupTimeDefaultValue = createSelector(
  selectMinDurationToPickupSettingValues,
  values => (values.length > 0 ? values.find(value => value.condition === null)?.value : null),
);

export const selectMaxDurationToPickupTimeDefaultValue = createSelector(
  selectMaxDurationToPickupSettingValues,
  values => (values.length > 0 ? values.find(value => value.condition === null)?.value : null),
);

export const selectAllowContactDriverAfterTripDurationDefaultValue = createSelector(
  selectAllowContactDriverAfterTripDuration,
  values => (values.length > 0 ? values.find(value => value.condition === null)?.value : null),
);

export const selectMinDurationToPickupTimeAreaValue = createSelector(
  selectMinDurationToPickupSettingValues,
  selectAreaId,
  (values, areaId) =>
    values.length > 0 && areaId !== null ? values.find(value => value.condition?.value === areaId)?.value : null,
);

export const selectMaxDurationToPickupTimeAreaValue = createSelector(
  selectMaxDurationToPickupSettingValues,
  selectAreaId,
  (values, areaId) => {
    return values.length > 0 && areaId !== null ? values.find(value => value.condition?.value === areaId)?.value : null;
  },
);

export const selectAllowContactDriverAfterTripDurationAreaValue = createSelector(
  selectAllowContactDriverAfterTripDuration,
  selectAreaId,
  (values, areaId) =>
    values.length > 0 && areaId !== null ? values.find(value => value.condition?.value === areaId)?.value : null,
);

export const selectMinDurationToPickupTime = createSelector(
  selectMinDurationToPickupTimeAreaValue,
  selectMinDurationToPickupTimeDefaultValue,
  (areaValue, defaultValue) => areaValue ?? defaultValue,
);

export const selectMaxDurationToPickupTime = createSelector(
  selectMaxDurationToPickupTimeAreaValue,
  selectMaxDurationToPickupTimeDefaultValue,
  (areaValue, defaultValue) => areaValue ?? defaultValue,
);

export const selectAllowContactDriverAfterTripDurationValue = createSelector(
  selectAllowContactDriverAfterTripDurationAreaValue,
  selectAllowContactDriverAfterTripDurationDefaultValue,
  (areaValue, defaultValue) => areaValue ?? defaultValue,
);

export const selectIsLoadingPickupSuggestions = createSelector(
  selectBookRideState,
  state => state.isLoadingPickupSuggestions,
);
export const selectIsLoadingDropoffSuggestions = createSelector(
  selectBookRideState,
  state => state.isLoadingDropoffSuggestions,
);
export const selectTemplateId = createSelector(selectBookRideState, state => state.templateId);
export const selectPackageId = createSelector(selectBookRideState, state => state.packageId);
export const selectVehicleTypeId = createSelector(selectBookRideState, state => state.vehicleTypeId);
export const selectFeatureIds = createSelector(selectBookRideState, state => state.featureIds);
export const selectPickupLocation = createSelector(selectBookRideState, state => state.pickupLocation);
export const selectQuote = createSelector(selectBookRideState, state => state.quote);
export const selectPickupLocationCoordinates = createSelector(selectPickupLocation, location =>
  location
    ? ({
        lat: location.lat,
        lng: location.lng,
      } as LatLng)
    : null,
);

export const selectDropoffLocation = createSelector(selectBookRideState, state => state.dropoffLocation);
export const selectDropoffLocationCoordinates = createSelector(selectDropoffLocation, location =>
  location
    ? ({
        lat: location.lat,
        lng: location.lng,
      } as LatLng)
    : null,
);

export const selectPickupSuggestions = createSelector(selectBookRideState, state => state.pickupSuggestions);
export const selectDropoffSuggestions = createSelector(selectBookRideState, state => state.dropoffSuggestions);

export const selectAgentPickupLocations = createSelector(selectAgent, agent =>
  agent
    ? agent.pickup_locations
        .map(location => mapLocationToLocationVM(location))
        .sort((a, b) => a.formattedAddress.localeCompare(b.formattedAddress))
    : [],
);

export const selectAllowCustomPickupLocation = createSelector(
  selectAgent,
  agent => agent?.allow_custom_pickup_location ?? false,
);

export const selectAgentTemplates = createSelector(selectAgent, agent => mapAgentToTemplates(agent));

export const selectIsTemplates = createSelector(selectAgentTemplates, templates => templates.length > 0);

export const selectPackagesVM = createSelector(
  selectPackages,
  selectVehicleTypes,
  selectFeatures,
  (packages, vehicleTypes, features) =>
    packages.length > 0 ? mapPackagesToProductVM(packages, vehicleTypes, features) : [],
);

export const selectAllPackagesVM = createSelector(
  selectAllPackages,
  selectAllVehicleTypes,
  selectFeatures,
  (packages, vehicleTypes, features) =>
    packages.length > 0 ? mapPackagesToProductVM(packages, vehicleTypes, features) : [],
);

export const selectSelectedPackage = createSelector(selectPackageId, selectPackages, (id, packages) =>
  packages.find(p => p.id === id),
);

export const selectQuoteVM = createSelector(selectQuote, quote => (quote ? mapQuoteToQuoteVM(quote) : null));

export const selectRidesTableVM = createSelector(
  selectUpcomingRides,
  selectAreas,
  selectAllPackagesVM,
  (rides, areas, products) => mapBookingAggregateToUpcomingRidesTableVM(rides, areas, products),
);

export const selectMapCenter = createSelector(selectBusiness, business => {
  return business ? { lat: business.countryCenter.lat, lng: business.countryCenter.lng } : null;
});

export const selectMinDate = createSelector(
  selectMinDurationToPickupTime,
  selectAreaTimeZoneId,
  (duration, areaTimeZoneId) => dateTimeNowAddDuration(areaTimeZoneId, duration),
);

export const selectMaxDate = createSelector(
  selectMaxDurationToPickupTime,
  selectAreaTimeZoneId,
  (duration, areaTimeZoneId) => dateTimeNowAddDuration(areaTimeZoneId, duration),
);

export const selectLocalDateNow = createSelector(selectAreaTimeZoneId, areaTimeZoneId =>
  toZonedTime(new Date(), areaTimeZoneId),
);

export const selectNoteToDriver = createSelector(
  selectBusinessNote,
  selectBusinessSiteId,
  (businessNote, businessSiteId) => (businessNote ? mapBusinessNoteToNoteToDriver(businessNote, businessSiteId) : null),
);

export const selectRideDetails = createSelector(
  selectBookRideState,
  selectAreas,
  selectMapCenter,
  (state, areas, mapCenter) =>
    state.selectedRideBookingAggregate
      ? mapBookingAggregateToRideDetails(state.selectedRideBookingAggregate, areas, mapCenter)
      : null,
);
export const selectRideDetailsIsLoading = createSelector(selectBookRideState, state => state.isLoadingRideDetails);

export const selectIsFormValid = createSelector(
  selectIsLoading,
  selectIsLoadingQuote,
  selectDropoffLocation,
  selectQuoteVM,
  (isLoading, isLoadingQuote, dropoffLocation, quote) => {
    return !isLoadingQuote && !isLoading && !(dropoffLocation != null && quote == null);
  },
);

export const selectSelectedRideId = createSelector(selectBookRideState, state => state.selectedRideId);
export const selectIsSendTrackingLinkInProgress = createSelector(
  selectBookRideState,
  state => state.isTrackingLinkSending,
);

export const selectIsCallDriverInProgress = createSelector(selectBookRideState, state => state.isCallingDriver);

export const selectIsCancelBookingInProgress = createSelector(
  selectBookRideState,
  state => state.isCancelBookingInProgress,
);

export const selectEta = createSelector(selectBookRideState, state => state.eta);
export const selectNearbyDriversTelemetries = createSelector(
  selectBookRideState,
  state => state.nearbyDriversTelemetries,
);

export const selectDisabledToCallDriver = createSelector(
  selectBookRideState,
  selectAllowContactDriverAfterTripDurationValue,
  selectLocalDateNow,
  selectAreaTimeZoneId,
  (state, allowContactDriverAfterTripDuration, localNow, timeZoneId) => {
    if (!state.selectedRideBookingAggregate) {
      return true;
    }
    const status = getRideStatus(state.selectedRideBookingAggregate);

    if (status === RideStatus.COMPLETED) {
      const endDate = toZonedTime(state.selectedRideBookingAggregate.trip.ended_at, timeZoneId);
      endDate.setMilliseconds(endDate.getMilliseconds() + allowContactDriverAfterTripDuration);
      return localNow > endDate;
    }

    return !(status === RideStatus.ACCEPTED || status === RideStatus.IN_PROGRESS);
  },
);
