import {
  Agent,
  Area,
  BookingAggregate,
  BusinessNote,
  Feature,
  Location,
  Package,
  Quote,
  VehicleType,
} from '../../../shared/models/firestore.model';
import { LocationVM, SuggestionsVM } from '../../../shared/models/location.vm';
import { AgentTemplateVM } from '../../../shared/models/business.vm';
import { ProductVM } from '../../../shared/models/package.vm';
import { QuoteVM, UpcomingRidesTableRow, UpcomingRidesTableVM } from '../../../shared/models/rides.vm';
import { getRideStatus } from '../../rides/store/mappings';
import { AutoCompleteResponse } from '../../../shared/models/b2b-web-api.model';
import { PlaceDetailsResponse } from '../../../shared/models/google-maps.model';
import { PaymentMethodType } from '../../../shared/models/payment-methods.model';
import { StringUtils } from '../../../shared/utils/string-utils';

export function mapLocationToLocationVM(location: Location) {
  return {
    formattedAddress: location.formatted_address,
    lat: location.lat,
    lng: location.lng,
    heading: formatHeading(location.formatted_address),
  } as LocationVM;
}

export function mapAgentToTemplates(agent: Agent): AgentTemplateVM[] {
  const locations = agent?.pickup_locations ?? [];
  if (locations.length === 0) {
    return [];
  }

  if (locations.length > 1) {
    [...locations].sort((a, b) => a.formatted_address.localeCompare(b.formatted_address));
  }

  return locations.map(location => {
    return {
      templateId: location.formatted_address,
      name: location.formatted_address.slice(0, 15),
      pickupLocation: mapLocationToLocationVM(location),
      allowCustomPickupLocation: agent.allow_custom_pickup_location,
      phone: agent.phone,
      note: '',
    } as AgentTemplateVM;
  });
}

export function mapPackagesToProductVM(
  packages: Package[],
  vehicleTypes: VehicleType[],
  features: Feature[],
): ProductVM[] {
  return packages
    .map(p => ({
      id: p.id,
      name: p.name,
      imageUrl: p.icon,
      seats: vehicleTypes.find(v => v.id === p.vehicle_type_id)?.seats ?? 0,
      luggage: features.find(f => p.feature_ids.includes(f.id) && f.name.includes('Luggage'))?.count ?? null,
      eta: '',
      sortOrder: p.sort_order,
      vehicleTypeId: p.vehicle_type_id,
      featureIds: p.feature_ids,
    }))
    .sort((a, b) => a.sortOrder - b.sortOrder);
}

export function mapQuoteToQuoteVM(quote: Quote): QuoteVM {
  return {
    id: quote.id,
    totalAmount: quote.total_price.display,
    externalFeeAmount: quote.external_fees_amount?.display ?? null,
    isInfoVisible: false,
  };
}

export function mapBookingAggregateToUpcomingRidesTableVM(
  aggregates: BookingAggregate[],
  areas: Area[],
  products: ProductVM[],
): UpcomingRidesTableVM {
  return {
    rows: aggregates?.map(aggregate => {
      const selectedProduct = products?.find(p => p.id === aggregate.booking.package_id);
      const etaInMinutes = aggregate.pickup_eta?.eta ? Math.round(+aggregate.pickup_eta.eta / 60) : null;
      const area = areas.find(a => a.id === aggregate.booking.area_id);
      return {
        id: aggregate.booking.id,
        createdAt: aggregate.booking.created_at,
        pickupAt: aggregate.booking.pickup_at,
        package: selectedProduct?.name ?? '',
        seats: selectedProduct?.seats ?? 0,
        status: getRideStatus(aggregate),
        eta: etaInMinutes,
        note: aggregate.booking.notes?.length > 0 ? aggregate.booking.notes[0].body : '',
        licensePlate: aggregate.vehicle?.license_plate,
        dropoff: StringUtils.splitOnFirstComma(
          aggregate.booking.dropoff?.formatted_address,
          aggregate.booking.dropoff?.name,
        ),
        area,
      } as UpcomingRidesTableRow;
    }),
  };
}

export function mapPlaceDetailsResponseToLocationVM(
  response: PlaceDetailsResponse,
  location: SuggestionsVM,
): LocationVM {
  return {
    ...location,
    formattedAddress: response.result.formattedAddress,
    lat: response.result.geometry.location.lat,
    lng: response.result.geometry.location.lng,
    name: response.result.name,
  };
}

export function mapAutoCompleteResponseToSuggestionsVM(response: AutoCompleteResponse): SuggestionsVM[] {
  return response.predictions.map(prediction => ({
    source: response.source,
    placeId: prediction.placeId,
    formattedAddress: prediction.formattedAddress,
    name: prediction.name,
    lat: prediction.location?.lat,
    lng: prediction.location?.lng,
    heading: formatHeading(prediction.formattedAddress, prediction.name),
    text: getLocationText(prediction.formattedAddress, prediction.name),
  }));
}

function formatHeading(formattedAddress: string, name?: string): string {
  return name ? `${name}` : getAddressHeading(formattedAddress);
}

function getAddressHeading(str: string) {
  return str ? str.split(/( - |, )/)[0] : '';
}

export function getLocationText(formattedAddress: string, name?: string) {
  if (formattedAddress && name && formattedAddress !== name) {
    return formattedAddress;
  }
  return getAddressText(formattedAddress, name);
}

const getAddressText = (formattedAddress: string, name: string) => {
  const str = formattedAddress || name;
  const parts = str.split(/(?: - |, )/); // Non-capturing group to avoid including delimiters
  const result = parts.slice(1); // Selects all elements after the first part

  // If there are more than one element, join them with ", ", otherwise return the single element
  return result.length > 1 ? result.slice(0, -1).join(', ') : result.join();
};

export function mapBusinessNoteToNoteToDriver(businessNote: BusinessNote, businessSiteId?: string): string | null {
  const businessSiteNote = businessNote.sites?.find(site => site.business_site_id === businessSiteId);
  const note = businessSiteNote ? businessSiteNote.note : businessNote.note;
  return StringUtils.newLineFromFirebase(note);
}
