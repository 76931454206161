import { LocationVM } from './location.vm';
import { LatLng } from './latlng.model';
import { Area, DriverTelemetry, Package, VehicleType } from './firestore.model';
import { Payment } from './payment.model';
import { PaymentMethod } from './payment-methods.model';

export type RidesTab = 'Recent' | 'Scheduled' | 'Failed payments';

export enum RideStatus {
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  ENDED = 'Ended',
  IN_PROGRESS = 'In progress',
  ACCEPTED = 'Accepted',
  EXPIRED = 'Expired',
  SCHEDULED = 'Scheduled',
  LOOKING_FOR_DRIVER = 'Looking for driver',
  CREATED = 'Created',
  CLAIMED = 'Claimed by driver',
}

export interface RideDetailsVM {
  id: string;
  createdAt: number;
  pickupAt: number | null;
  status: RideStatus;
  price: string;
  pickupAddress: string;
  pickupAddressName?: string;
  dropOffAddress: string;
  dropOffAddressName?: string;
  paymentMethod: PaymentMethod | null;
  pickupLocation: LatLng | null;
  dropOffLocation: LatLng | null;
  polyline: string | null;
  mapCenter: LatLng | null;
  externalFeesAmount: string | null;
  driver: string | null;
  vehicle: string | null;
  note: string | null;
  phoneNumber: string;
  package: Package | null;
  quoteId: string | null;
  vehicleType: VehicleType | null;
  driverTelemetry: DriverTelemetry | null;
  area: Area | null;
  payment: Payment | null;
}

export interface CreateRideVM {
  pickupAt?: string;
  pickupLocation: LocationVM;
  dropoffLocation: LocationVM;
  noteToDriver: string;
  contactNumber: string;
  paymentMethod: string;
  vehicleTypeId: string;
  featureIds: string[];
  note: string;
  phone: string;
}

export interface QuoteVM {
  id: string;
  totalAmount: string;
  externalFeeAmount: string;
  isInfoVisible: boolean;
}

export interface UpcomingRidesTableVM {
  rows: UpcomingRidesTableRow[] | null;
}

export interface UpcomingRidesTableRow {
  id: string;
  createdAt: number;
  pickupAt: number;
  package: string;
  status: RideStatus;
  eta: number;
  vehicleType: string;
  note: string;
  seats: number;
  licensePlate: string;
  dropoff?: [string, string];
  area: Area;
}
