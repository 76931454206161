import { Entity } from './firestore.model';

export interface Payment extends Entity {
  business_id: string;
  business_site_id: string;
  id: string;
  status: PaymentMethodStatus;
}

export enum PaymentMethodStatus {
  PAYMENT_STATUS_NONE = 0,
  PAYMENT_STATUS_REGISTERED = 1,
  PAYMENT_STATUS_PAID = 2,
  PAYMENT_STATUS_FAILED = 3,
}
